<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据中心</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">人员数据汇集</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">查看学员</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel">姓名:</span>
              <el-input v-model="retrievalDatas.userName" clearable size="small" placeholder="请输入姓名"></el-input>
            </div>
            <div title="身份证号" class="searchboxItem ci-full">
              <span class="itemLabel">身份证号:</span>
              <el-input v-model="retrievalDatas.idcard" clearable size="small" placeholder="请输入身份证号"></el-input>
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel">手机号:</span>
              <el-input v-model="retrievalDatas.mobile" clearable size="small" placeholder="请输入手机号"></el-input>
            </div>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button type="primary" class="bgc-bv" round @click="downloadData()">导出</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader">
              <el-table-column label="序号" align="center" type="index" fixed :index="indexMethod" width="100px" />
              <el-table-column label="身份证号" align="left" show-overflow-tooltip prop="idcard" min-width="150" />
              <el-table-column label="考生来源1" align="left" show-overflow-tooltip prop="employeeSource1" min-width="150" />
              <el-table-column label="考生来源2" align="left" show-overflow-tooltip prop="employeeSource2" min-width="150" />
              <el-table-column label="学员姓名" align="left" show-overflow-tooltip prop="userName" width="100" />
              <el-table-column label="性别" align="left" show-overflow-tooltip prop="sex" width="100" />
              <el-table-column label="民族" align="left" show-overflow-tooltip prop="nationName" width="120" />
              <el-table-column label="出生日期" align="left" show-overflow-tooltip prop="birthday" width="120" />
              <el-table-column label="工作单位" align="left" show-overflow-tooltip prop="workUnit" width="300" />
              <el-table-column label="理论考试" align="left" show-overflow-tooltip width="250">
                <template slot-scope="scope">
                  {{ scope.row.examTheoryStart || '' }}-{{ scope.row.examTheoryEnd || '' }}
                </template>
              </el-table-column>
              <el-table-column label="技能考试" align="left" show-overflow-tooltip prop="projectName" min-width="250">
                <template slot-scope="scope">
                  {{ scope.row.examSkillStart || '' }}-{{ scope.row.examSkillEnd || '' }}
                </template>
              </el-table-column>
              <el-table-column label="综合考试" align="left" show-overflow-tooltip prop="activityName" min-width="250">
                <template slot-scope="scope">
                  {{ scope.row.examSynthesisStart || '' }}-{{ scope.row.examSynthesisEnd || '' }}
                </template>
              </el-table-column>
              <el-table-column label="准考证号" align="left" show-overflow-tooltip prop="candidateNo" width="200" />
              <el-table-column label="理论成绩" align="left" show-overflow-tooltip prop="theoryGrade" width="100" />
              <el-table-column label="技能成绩" align="left" show-overflow-tooltip prop="skillGrade" width="100" />
              <el-table-column label="综合成绩" align="left" show-overflow-tooltip prop="synthesisGrade" min-width="100" />
              <el-table-column label="认定结果" align="left" show-overflow-tooltip prop="identifiedResult" min-width="100" />
              <el-table-column label="证书编号" align="left" show-overflow-tooltip prop="certificateNo" min-width="150" />
              <el-table-column label="发证日期" align="left" show-overflow-tooltip prop="issueDate" min-width="150" />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "operate_personnelDataCollectionJiLin_studentList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 上个页面传过来的数据
      lastPageData: {
        integrationId: "", // 任务id
      },
      // 页面检索数据
      retrievalDatas: {
        userName: '', // 姓名
        idcard: '', // 身份证号
        mobile: '', // 手机号
      },
    };
  },
  created() {
    this.lastPageData.integrationId = this.$route?.query?.integrationId ?? "";
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    // 获取 - 列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        integrationId: this.$route?.query?.integrationId ?? ""
      };
      if (this.retrievalDatas.userName) {
        params.userName = this.retrievalDatas.userName;
      }
      if (this.retrievalDatas.idcard) {
        params.idcard = this.retrievalDatas.idcard;
      }
      if (this.retrievalDatas.mobile) {
        params.mobile = this.retrievalDatas.mobile;
      }
      this.doFetch({
        url: "/biz/ct/datacenter/employeePageListJL",
        params,
        pageNum
      });
    },
    // 导出
    downloadData() {
      const params = {
        integrationId: this.lastPageData.integrationId
      };
      if (this.retrievalDatas.userName) {
        params.userName = this.retrievalDatas.userName;
      }
      if (this.retrievalDatas.idcard) {
        params.idcard = this.retrievalDatas.idcard;
      }
      if (this.retrievalDatas.mobile) {
        params.mobile = this.retrievalDatas.mobile;
      }
      this.$post("/biz/ct/datacenter/employeeListJLExport", params).then((res) => {
        const arr = [...res.data];
        for (let item of arr) {
          if (!this.downloadItems.includes(item.taskId)) {
            this.$store.dispatch("pushDownloadItems", item.taskId);
          } else {
            this.$message.warning(
              "[" + item.fileName + "]已经申请下载,请耐心等待"
            );
          }
        }
      })
        .catch(() => {
          return;
        });
    },
  },
};
</script>
<style lang="less" scoped></style>
